import React, { useEffect, useState } from "react";
import "./Navbarnew.scss";
import { useWeb3React } from "@web3-react/core";
import { Link } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import Modal from 'react-bootstrap/Modal';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Accordion } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
const NavbarNew = ({ setIndexxxx }) => {
  const currentYear = new Date().getFullYear();
  const [stickyClass, setStickyClass] = useState(false);
  const [pay, setPay] = useState(false);
  const { account } = useWeb3React();
  const { login, logout } = useAuth();
  const [showmobilesidebar, setShowMobileSidebar] = useState(false);
  const handleCloseMobileSidebar = () => setShowMobileSidebar(false);
  const handleShowMobileSidebar = () => setShowMobileSidebar(true);
  const [copied, setCopied] = useState(false);
  const [showconnect, setShowconnect] = useState(false);
  const handleCloseconnect = () => setShowconnect(false);
  const handleShowconnect = () => setShowconnect(true);

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  useEffect(() => {
    const handleMessage = (event) => {
      // console.log('Received message:', event.data);
      if (event.data.includes('istomiPAY')) {
        setPay(true)
      }

    };
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);


  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // console.log("windowscroll", windowHeight);
      windowHeight > 20 ? setStickyClass(true) : setStickyClass(false);
    }
  };


  const Closemodal = () => {
    window.$('#exampleModalwhite44445').modal('hide');
    // history.push('/home')
  };
  const openmodals = () => {
    window.$('#exampleModalwhite44445').modal('show');
  }

  const textCopiedFun = () => {
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 1000)
  }


  const trustWallet = async () => {
    console.log("trust")
    localStorage.setItem("flag", "true");
    localStorage.setItem("connectorId", "walletconnect");
    if (account) {
      logout('walletconnect');
      localStorage.clear();
    } else {
      login("walletconnect");
      setShowconnect(false)
    }
  };

  const trustWallet1 = async () => {
    console.log("tomi")
    localStorage.setItem("flag", "true");
    localStorage.setItem("connectorId", "walletconnect");
    if (account) {
      logout('walletconnect');
      localStorage.clear();
    } else {
      login("walletconnect");
      setShowconnect(false)
    }
  };
  const connectMetaMask1 = () => {
    localStorage.setItem("connectorId", "injected");
    localStorage.setItem("flag", "true");
    if (account) {
      logout();
    } else {
      login("injected");
      setShowconnect(false)
    }
  };

  const logoutwallet = () => {
    const connectorId = window.localStorage.getItem("connectorId")
    logout(connectorId);
    localStorage.removeItem('connectorId')
    window.location.reload()
  }

  return (
    <>
      <section className="main-navbar dfdfdfdfdfvfvrve mmmkkkmmkk fgfgfgfgfgf">
        <div className="custom_container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 m-auto p-0">
              <nav className="navbar  navbar-expand-xl ">
                <a className="hhjjjhhjjj">
                  <Link to="/"
                  >
                    <img
                      src="\assets\tomilogo.png"
                      alt="img"
                      className="img-fluid gcsgcdgsdvcgc"
                    />
                  </Link>
                </a>
                <a href="#" className="navbar-brand sdvgdvsdhvd">
                  <Link to="/"
                  >
                    <img
                      src="https://res.cloudinary.com/drt6vurtt/image/upload/v1680538381/logo_yrpftq.svg"
                      alt="img"
                      className="img-fluid sdsdsdsffgdsds gcsgcdgsdvcgc"
                    />
                    <img
                      src="/newmmm/tomipay-logo.svg"
                      alt="img"
                      className="img-fluid tomipay-logo d-none"
                    />
                  </Link>
                </a>
                <div className="ahsbahsba">
                  <Link to="/">
                    {/* <img src="/newmmm/logo.svg" alt="img" className="img-fluid vsvtsvtsc" /> */}
                    <img src="/newmmm/tomipay-logo.svg" alt="img" className="img-fluid tomipay-logo d-none" />

                  </Link>
                </div>
                {/* <div className="centerdiv">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      Features
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.87151 4.87877L3.50027 4.50753L2.75781 5.25L3.12904 5.62123L6.62904 9.12123L7.00027 9.49246L7.37151 9.12123L7.70936 8.78337L6.56641 7.57367L3.87151 4.87877ZM7.32954 7.67827L8.62287 7.86986L10.8715 5.62123L11.2427 5.25L10.5003 4.50753L10.129 4.87876L7.32954 7.67827Z" fill="#0E1120" />
                      </svg>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                      <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      Features
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.87151 4.87877L3.50027 4.50753L2.75781 5.25L3.12904 5.62123L6.62904 9.12123L7.00027 9.49246L7.37151 9.12123L7.70936 8.78337L6.56641 7.57367L3.87151 4.87877ZM7.32954 7.67827L8.62287 7.86986L10.8715 5.62123L11.2427 5.25L10.5003 4.50753L10.129 4.87876L7.32954 7.67827Z" fill="#0E1120" />
                      </svg>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Company</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                      <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                    Help
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.87151 4.87877L3.50027 4.50753L2.75781 5.25L3.12904 5.62123L6.62904 9.12123L7.00027 9.49246L7.37151 9.12123L7.70936 8.78337L6.56641 7.57367L3.87151 4.87877ZM7.32954 7.67827L8.62287 7.86986L10.8715 5.62123L11.2427 5.25L10.5003 4.50753L10.129 4.87876L7.32954 7.67827Z" fill="#0E1120" />
                      </svg>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                      <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                    Blog
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.87151 4.87877L3.50027 4.50753L2.75781 5.25L3.12904 5.62123L6.62904 9.12123L7.00027 9.49246L7.37151 9.12123L7.70936 8.78337L6.56641 7.57367L3.87151 4.87877ZM7.32954 7.67827L8.62287 7.86986L10.8715 5.62123L11.2427 5.25L10.5003 4.50753L10.129 4.87876L7.32954 7.67827Z" fill="#0E1120" />
                      </svg>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                      <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div> */}
                <div className="disconnectbtn">
                  {account ?
                    (
                      <button onClick={logoutwallet}>disconnect Wallet</button>
                    )
                    :
                    (
                      <button onClick={handleShowconnect}>Connect Wallet</button>
                    )
                  }
                </div>
                <img src="\assets\dotss.png" alt="img" className="img-fluid dotsss d-none"/>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <div className="fornmoblieview">
        
      </div>


      {/* hamza mobile sidebar navbar new............... */}
      <div
        class="modal fade svdshdvshgdsh"
        id="exampleModalwhite44445"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content ">
            <div class="modal-body">
              <div className="main-headersdsd">
                <div className="shvdshvd ">
                  <div className="sdhvsdsdsds">
                    <h3 class="modal-title " id="exampleModalLabel">
                      Contribution
                    </h3>
                    <p className="sdfsdfsdf ">
                      The tomiDAO treasury accepts contribution at the form of Ethereum based tokens & NFTs at this address:
                    </p>
                  </div>
                  <button
                    type="button"
                    class="close"
                    onClick={Closemodal}
                    aria-label="Close"
                  >
                    <img
                      src="/newmmm/close-circle.png"
                      className="img-fluid  "
                      alt=""
                    />
                  </button>
                </div>
                <div className=" inputcard">
                  <form>
                    <div class="form-group dhghvd">
                      <div class="jhjhjh">
                        <label for="exampleInputEmail1">Treasury Address:</label>
                        <div className='gigvgdbhh'>
                          {copied ? <> <h6>0x2c6eF2306E2B81FACD213e1D66509847e2159d64</h6><p className='sfdsfdsfdsfdsf'>Copied!</p> </> : <>
                            <h6>0x2c6eF2306E2B81FACD213e1D66509847e2159d64</h6>
                            <CopyToClipboard text='0x2c6eF2306E2B81FACD213e1D66509847e2159d64' onCopy={textCopiedFun}>
                              <img
                                src="/newmmm/copy.svg"
                                className="img-fluid  dsfadsfadfadfa"
                                alt=""
                              />
                            </CopyToClipboard>
                          </>}
                        </div>
                      </div>
                    </div>
                    {/* <button type="submit"  class="btn btn-common gff mt-4 btn-lg btn-block btn-commondull"> Send Request</button> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <Offcanvas className="mobile-sidebar" show={showmobilesidebar} onHide={handleCloseMobileSidebar}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title> <img src="\logo-footer.svg" alt="img" className="img-fluid" /> </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Products</Accordion.Header>
              <Accordion.Body>
                <div className="sidebarinner">
                  <a className="links" href="https://tomi.com/browser">Browser</a>
                  <a href="https://tdns.network" target="_blank" className="links">tDNS</a>
                  <a href="https://tomi.com/wallet" className="links">tomiPAY</a>
                  <a href="https://tomi.com/mpone" className="links">Nodes</a>
                  <a href="https://tomi.com/tpl" className="links">Tomi Privacy Layer</a>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Token</Accordion.Header>
              <Accordion.Body>
                <div className="sidebarinner">
                  <a href="http://bit.ly/3IKEJa4" target="_blank" className="links">Buy on HUOBI</a>
                  <a href="http://bit.ly/3CQlybG" target="_blank" className="links">Buy on GATE.IO</a>
                  <a className="links" href="https://bit.ly/3Zxfn6d" target="_blank">Buy on MEXC</a>
                  <a className="links" href="https://bit.ly/3NWOoxt" target="_blank">Buy on Crypto.com</a>
                  <a href="http://bit.ly/43HVBqL" target="_blank" className="links">Buy on Phemex</a>
                  <a href="https://bit.ly/3KYnl1H" target="_blank" className="links">Buy on Bitget</a>
                  <a href="https://staking.tomi.com/" className="links">Staking</a>
                  {/* <a href="" className="links">Staking</a>
                  <a href="" className="links">Auction</a> */}
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>Token Info</Accordion.Header>
              <Accordion.Body>
                <div className="sidebarinner">
                  <a className="links" href="https://coinmarketcap.com/currencies/tominet/" target="_blank">CoinMarketCap</a>
                  <a href="https://www.coingecko.com/en/coins/tominet" target="_blank" className="links">CoinGecko</a>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Governance</Accordion.Header>
              <Accordion.Body>
                <div className="sidebarinner">
                  <a className="links" href="https://dao.tomi.com" target="_blank">DAO</a>
                  <a href="https://artdao.tomi.com/" target="_blank" className="links">ArtDAO</a>
                  <a href="https://tomi.com/pioneer" className="links">Pioneers</a>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Community</Accordion.Header>
              <Accordion.Body>
                <div className="sidebarinner">
                  <Link className="links" to="/forumsignup">Forum</Link>
                  <a href="https://discord.gg/tomipioneers" target="_blank" className="links">Discord</a>
                  <a href="https://twitter.com/tomipioneers" target="_blank" className="links">Twitter</a>
                  <a href="https://t.me/tomipioneers" target="_blank" className="links">Telegram</a>
                  <a className="links" href="https://www.tiktok.com/@tominetwork" target="_blank">TikTok</a>
                  <a href="https://www.reddit.com/r/tomipioneers/" className="links">Reddit</a>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>About</Accordion.Header>
              <Accordion.Body>
                <div className="sidebarinner">
                  <a className="links" href="https://tomi.com/aboutus">About tomi</a>
                  <a href="https://tomi.com/blog" className="links">Blog</a>
                  <a href="https://tomi.com/press" className="links">Press</a>
                  <a href="/newmmm/Tomi_Free_As_In_Speech_Whitepaper_V1.0.pdf" target="_blank" className="links">Whitepaper</a>
                  <a className="links" href="https://tomi-2.gitbook.io/tomi/" target="_blank">GitBook</a>
                  <a className="links" href="/newmmm/Certik-Report-Tomi.pdf" target="_blank">
                    Certik Report
                  </a>
                  <a className="links" href="/newmmm/SlowMist Audit Report - Tomi.pdf" target="_blank">
                    Slowmist Report
                  </a>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <div className="disconnectbtn mt-5 mb-5">
            {account ?
              (
                <button onClick={logoutwallet}>disconnect Wallet</button>
              )
              :
              (
                <button onClick={handleShowconnect}>Connect Wallet</button>
              )
            }
          </div>
          <div className="sidebarfooter">
            <div className="socialicons">
              <a href="https://tomi.com/forumsignup">
                <img src="\assets\sidebarscoialicons\message.svg" alt="icon" className="icon" />
              </a>
              <a href="https://discord.gg/tomipioneers" target="_blank">
                <img src="\assets\sidebarscoialicons\discord.svg" alt="icon" className="icon" />
              </a>
              <a href="https://twitter.com/tomipioneers">
                <img src="\assets\sidebarscoialicons\twitter.svg" alt="icon" className="icon" />
              </a>
              <a href="https://t.me/tomipioneers" target="_blank">
                <img src="\assets\sidebarscoialicons\telegram.svg" alt="icon" className="icon" />
              </a>
              <a href="https://www.tiktok.com/@tominetwork" target="_blank">
                <img src="\assets\sidebarscoialicons\tiktok.svg" alt="icon" className="icon" />
              </a>
              <a href="https://www.reddit.com/r/tomipioneers/" target="_blank">
                <img src="\assets\sidebarscoialicons\reddit.svg" alt="icon" className="icon" />
              </a>
            </div>
            <p className="foottextside">© {currentYear} tomi, all rights reserved.</p>
            <p className="foottextside"><a href="https://tomi.com/terms" target="_blank">Terms of use</a>  |  <a href="https://tomi.com/privacy" target="_blank">Privacy policy</a>  | <a onClick={openmodals}>Contribution</a></p>
            <p className="foottextside"><a href="https://tomi.com/contactus">Contact us</a></p>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <Modal className="connectmodal" show={showconnect} onHide={handleCloseconnect} centered>
        <Modal.Body>
          <div className="modalhead">
            <h4 className="modalheading">Connect Wallet</h4>
            <button className="btnclose" onClick={handleCloseconnect}><img src="\assets\close-icon.svg" alt="cross" className="cross_btns" /></button>
          </div>
          <div className="connectwalletdiv">
            <div className="wallet MOBIKEDEISGNSS" onClick={connectMetaMask1}>
              <img src="\assets\metamask.svg" alt="logo" className="walletimg" />
              <p className="walletname">Metamask</p>
            </div>

            {/* <div className="wallet" onClick={trustWallet}>
              <img src="\assets\walletconnect.svg" alt="logo" className="walletimg" />
              <p className="walletname">WalletConnect</p>
            </div> */}

            {/* condition for is tomipay browswer */}
            {pay ?
              <div className="wallet" onClick={trustWallet1}>
                <img src="\assets\tomiPAYLogo.png" alt="logo" className="walletimg abb" />
                <p className="walletname">tomiPAY</p>
              </div>
              :
              <div className="wallet" onClick={trustWallet}>
                <img src="\assets\walletconnect.svg" alt="logo" className="walletimg" />
                <p className="walletname">WalletConnect</p>
              </div>
            }
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NavbarNew;