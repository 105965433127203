import React from 'react'
import './power.scss'

const Power = () => {
  return (
    <>
      <section className="mainpower">
        <img src="/assets/Hotbg.png" alt="mainpowerbg" className="mainpowerbg" />
       <div className='mainbannerimg d-none'>
       <img src='\assets\mobilebg.png' alt='img' className='img-fluid bgbgbg '/>
       </div>
        {/* <span className='powerleftshade'></span>
        <span className="powerrightshade"></span> */}
     
        <h2 className="powerhead">Power Your tomi Experience with <span className="pinkclr">TOMI Token</span></h2>
        <p className="powerpara">
        Unlock exclusive features, rewards, and governance rights within the tomi ecosystem. Buy TOMI on top exchanges (Bybit, Gate.io,...) & stake for additional benefits. Join the tomi revolution!
        </p>
   
      </section>
    </>
  )
}

export default Power
