import React from "react";
import "./newbanner.scss";

const Newbanner = () => {
  return (
    <>
      <section className="newbanner">
        {/* <img src="/assets/bannerbg.png" alt="img" className="bannerbgimg" /> */}
        <img src="\assets\bannerone.png" alt="img" className="bannerbgimg" />
        <img src="\assets\formblbg.png" alt="img" className="img-fluid mblimgbg d-none" />

        <div className="custom_container">
          {/* <p className="explorepara">
            <span className="greyclr">Explore </span>
            <span className="greyclr">//</span>
            <span className="pinkclr">tomi Token</span>
          </p> */}
          <h1 className="unlockhead">
          Stake $TOMI, Earn Rewards
          </h1>
          <p className="usepara">
          Put your $TOMI to work - stake securely, and support the TOMI ecosystem
          </p>

          {/* <button className="pinkbtn">
            Get Early Access
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.46956 4.53032L4.93923 3.99999L5.99989 2.93933L6.53022 3.46966L10.5302 7.46966L11.0605 7.99999L10.5302 8.53032L10.034 9.02649L8.79572 7.85649L5.46956 4.53032ZM8.79958 8.13964L9.07328 9.98726L6.53022 12.5303L5.99989 13.0607L4.93923 12L5.46956 11.4697L8.79958 8.13964Z" fill="#E8F2FF" />
            </svg>
          </button> */}

        </div>
      </section>
    </>
  );
};

export default Newbanner;
